import { t } from '@/plugins/i18n';
import isValidPhoneNumber from '@/util/isValidPhoneNumber';

export const required = (field) => (value) => !!value || t('form.field.required', { field });

export const minLength = (field, min) => (value) =>
  value.length >= min || t('form.field.minLength', { field, min });

export const maxLength = (field, max) => (value) =>
  value.length <= max || t('form.field.maxLength', { field, max });

export const validPhoneNumber = (field) => (value) =>
  isValidPhoneNumber(value) || t('form.field.valid', { field });
